import Vue from 'vue'
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/_diy-element.scss'
import './assets/css/diy.scss'

Vue.prototype.$echarts = echarts;

Vue.use(ElementUI, { size: 'small', zIndex: 5000 });
Vue.config.productionTip = false


// import 'mint-ui/lib/style.css'
// import { Picker, Popup ,Header,IndexList, IndexSection,Cell} from 'mint-ui';
// Vue.component(Picker.name, Picker);
// Vue.component(Popup.name, Popup);
// Vue.component(Header.name, Header);
// Vue.component(IndexList.name, IndexList);
// Vue.component(IndexSection.name, IndexSection);
// Vue.component(Cell.name, Cell);

import Mint from 'mint-ui';
import 'mint-ui/lib/style.css';

Vue.use(Mint);




import KcSelect from '@/components/kcSelect.vue'
Vue.component('KcSelect', KcSelect)

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {

      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
          setItem: function (k, val) {
              sessionStorage.setItem(k, val);

              // 初始化创建的事件
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

              // 派发对象
              window.dispatchEvent(newStorageEvent)
          }
      }
      return storage.setItem(key, newVal);
  }
}

Vue.prototype.$bodyWidth = document.getElementsByTagName("body")[0].clientWidth;
window.addEventListener("resize",function(){
  Vue.prototype.$bodyWidth = document.getElementsByTagName("body")[0].clientWidth;
  // console.log(Vue.prototype.$bodyWidth)
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
