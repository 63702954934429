<template>
  <div>
    <div class="headertoptitle">
       <div class="headertoptitleback" v-if="this.$route.query.hasOwnProperty('provinceId') " @click="goBack"></div>
      预付卡监管实时大数据报告
      </div>
    <div class="header">
      <div class="header-top">
        <div class="header-left">
          <p>数据更新至：</p>
          <div>{{ time }}</div>
        </div>
        <div class="header-right" @click="popupVisible = true">
          <p><img src="@/assets/imgs/header-site.png" alt="" /></p>
          <div>{{ siteName }}</div>
        </div>
      </div>
      <ul class="header-list">
        <router-link
          :to="val.path"
          tag="li"
          active-class="active"
          :class="{active: routerName =='HomePage' && ind === 0}"
          v-for="(val, ind) in listObj"
          :key="ind"
        >
          <p>{{ val.newName || val.name }}</p>
          <div>{{val.value}}<span>{{ val.unit }}</span>
          </div>
        </router-link>
      </ul>

      <mt-popup v-model="popupVisible" position="bottom">
        <div class="picker-toolbar-title">
          <div class="usi-btn-cancel" @click="siteCancle">取消</div>
          <div class="usi-btn-name">请选择地区</div>
          <div class="usi-btn-sure" @click="siteChange">确定</div>
        </div>
        <div class="pickers">
          <ul class="tab-top">
            <img src="@/assets/imgs/header-site.png" alt="" />
            <li v-if="cascaders.length == 1 && cascaders[0] == 1">全国</li>
            <li v-else :class="{active: activeInd == ind}"
                v-for="(val,ind) in nodeList.slice(1)"
                @click="activeInd = ind;setMove(activeInd)">
              {{val.regionName}}
              <span v-if="ind !== nodeList.slice(1).length - 1">></span>
            </li>
          </ul>
          <div class="tab-bottom">
            <ul class="tab-container"
                ref="touch"
                :style="{width:nodeList.length * 100 +'%',left: leftMove}"
                @touchstart="touchStart" @touchmove="touchmove" @touchend="touchend"
            >
              <li  v-for="(val,ind) in nodeList">
                <ul class="tab-list" v-if="ind == 0 && initList.length">
                  <li  v-for="(v,i) in initList"
                       @click.stop="lazyLoad(v,i)"
                       :class="{active: cascaders[1] == v.regionId || (cascaders.length == 1 && cascaders[0] == v.regionId)}">
                    <i class="el-icon-check"></i>
                    {{v.regionName}}
                    <span v-if="!v.leaf">></span>
                  </li>
                </ul>
                <ul class="tab-list" v-else-if="ind !== 0 && val.children && val.children.length">
                  <!--                {{val.children}}-->
                  <li  v-for="(v,i) in val.children"
                       @click.stop="lazyLoad(v,i)"
                       :class="{active: cascaders[ind+1] == v.regionId}">
                    <i class="el-icon-check"></i>
                    {{v.regionName}}
                    <span v-if="!v.leaf"> ></span>
                  </li>

                </ul>
                <div class="nodata" v-if="noData">暂无数据</div>
              </li>
            </ul>
          </div>

        </div>
      </mt-popup>
    </div>
  </div>
</template>

<script type="text/javascript">
import { sixStatistics, cityList, getTime, getInitCitys } from "@/api/common";
import { clone } from "lodash";

import { TabContainer, TabContainerItem, Cell  } from 'mint-ui';
export default {
  name: "Header",
  data() {
    return {
      initCitys: {
        provinceId: "",
        cityId: "",
        areaId: "",
      },
      siteName: "全国",
      regionData: {
        regionId: 1,
        regionType: 0,
        regionName: "全国",
        cityName:'',
        areaName:'',
        flag:1
      },
      time: "2020.11.18 17:26",
      showBtn: true,
      listObj: [
        { name: "发卡商户",newName:'企业商户', value: "0", unit: "家", path: "HomePage" },
        { name: "预付交易", value: "0", unit: "笔", path: "HomeTrade" },
        { name: "资金监管", value: "0", unit: "元", path: "HomeSupervise" },
        { name: "投诉处理", value: "0", unit: "件", path: "HomeAcceptance" },
        { name: "风险预警",newName:'风险监测', value: "0", unit: "家", path: "HomePolice" },
        { name: "风险处置", value: "0", unit: "笔", path: "HomePlace" },
        { name: "预付合同", value: "0", unit: "份", path: "HomePrepaidcontract" },
        { name: "消费结构",newName:'消费人次', value: "0", unit: "人次", path: "HomeConsumptionstructure" },
        { name: "疫情防控", value: "0", unit: "家", path: "HomeEpidemicpreventioncontrol" },
        { name: "任务协同", value: "0", unit: "件", path: "HomeTaskCoordination" },
        { name: "综合监管", value: "0", unit: "件", path: "HomeSynthesizeSupervise" },
        { name: "主体状态", value: "0", unit: "家", path: "HomeOther" }

      ],
      popupVisible: false,
      cityName:'',
      areaName:'',
      nodeList:[], //当前选中的node数组
      disabled: false,
      cascaders: [], //当前选中的ID数组
      initList:[], //初始列表的数据（含全国）
      paramLen:0,
      activeInd:0,
      isChange:false,
      routerName:'',
      noData: false,
      cur:null,
      startX:null,
      moveX:null,
      leftMove:0,
    };
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        this.routerName = val.name;

      },
      deep: true, //增加deep 观察对象的子对象变化
      immediate: true,
    },
    cascaders: {
      handler(val, oldVal) {
        console.log('cascaders',val)
        // this.sixStatistics();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    nodeList: {
      handler(val, oldVal) {
        console.log('nodeList',val)
        // this.sixStatistics();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    activeInd: {
      handler(val, oldVal) {
        console.log('activeInd',val)
        // this.sixStatistics();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },

  },
  created() {
    let query = this.$route.query;
    if(query && query.provinceId && (query.provinceId !== '0')){
      sessionStorage.setItem("initParams",'initParams')
      this.disabled = true;
      if(query.provinceId == '0') {
        query.provinceId = "";
      }else{
        this.cascaders[0] = query.provinceId - 0;
      }
      if(query.cityId == '0') {
        query.cityId = ""
      }else{
        this.cascaders[1] = query.cityId - 0;
      }
      if(query.areaId == '0') {
        query.areaId = ""
      }else{
        this.cascaders[2] = query.areaId - 0;
      }
      window.sessionStorage.setItem('initCitys',JSON.stringify(query))
      this.initCitys = JSON.parse(window.sessionStorage.getItem('initCitys'))
      this.disabled = true;
      if(this.initCitys.provinceId || this.initCitys.cityId || this.initCitys.areaId){
        console.log(this.cascaders)
        this.intData(true);

      }
    }else{
      window.sessionStorage.setItem('initCitys',"{}")
      this.cascaders = [1];
      this.intData();
      this.sixStatistics();
      sessionStorage.setItem("initParams",'init')
    }

  },
  mounted() {

    // console.log(this.$route)
    if (document.referrer == "https://rv.900sui.com/") {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }
  },
  methods: {
    goBack(){
      window.history.back()
    },
    changeTab(index) {
      this.cur = index;
      this.setMove(index);
    },
    /**
     * 设置偏移
     */
    setMove(index){
      this.leftMove = -(index * 100)+ '%';
      // var itemWidth = document.querySelector(".content-item").offsetWidth;
    },
    /**
     *  触摸开始事件
     */
    touchStart(e){
      // console.log("touchStart",e)
      this.startX = e.touches[0].clientX;
    },
    /**
     *  触摸移动事件
     */
    touchmove(e){
      // console.log("touchmove",e)
      this.moveX = e.touches[0].clientX;
    },
    /**
     *  触摸结束事件
     */
    touchend(e){
      console.log("moveX",this.moveX)
      console.log("startX",this.startX)
      if(this.moveX && this.startX){
        if(this.moveX-this.startX > 50){
          console.log("touchend---",this.activeInd)
          this.activeInd = this.activeInd-1;
          this.cur = this.activeInd;
          if(this.activeInd >= 0){
            this.setMove(this.activeInd);
          }else{
            this.activeInd = 0;
            this.cur = 0;
          }
        }else if(this.moveX-this.startX < -50){
          console.log("touchend+++",this.activeInd)
          this.activeInd = this.activeInd +1;
          if(this.activeInd >= this.nodeList.length-1){
            this.activeInd = this.nodeList.length-1;
          }
          this.setMove(this.activeInd);
          this.cur = this.activeInd;
        }
      }
      this.moveX = null;
      this.startX = null;
      console.log(this.activeInd)
    },


    intData(isParams) {
      if(sessionStorage.getItem("initParams") === 'init'){
        this.sixStatistics();
      }


      if(this.initCitys  && this.initCitys.provinceId){
      //   if(this.initCitys.provinceId || this.initCitys.cityId || this.initCitys.areaId){
        this.cityList(1,{},(list,ind)=>{
          console.log(list,'list')
          console.log(ind,'ind')
          let node1 = this.initCitys.provinceId == 1 ? list[ind] : list[ind + 1];
          this.nodeList = [{},node1];
          this.initList = [node1];
          if(this.initCitys.cityId){
            this.cityList(this.initCitys.provinceId,node1,(list1,ind1)=>{
              let node2 = list1[ind1];
              this.nodeList.push(node2);
              node1.children = [list1[ind1]];
              if(this.initCitys.areaId){
                this.cityList(this.initCitys.cityId,node2,(list2,ind2)=>{
                  let node3 = list2[ind2];
                  this.nodeList.push(node3);
                  node2.children = [list2[ind2]];
                  this.siteName = node3.regionName;
                  this.areaChange(node3,true)
                });
              }else{
                this.siteName = node2.regionName;
                this.areaChange(node2,true)
              }
            });
          }else{
            console.log("111111111111")
            this.siteName = node1.regionName;
            this.areaChange(node1,true)
          }
        });

      }else{
        this.cityList(1,{},(list)=>{

          this.nodeList = [list[0]];
          this.initList = list;
        });
      }
      this.getTime();
    },
    sixStatistics() {
      sixStatistics({
        regionId: this.regionData.regionId,
        regionType: this.regionData.regionType,
        flag: this.regionData.flag
      }).then((res) => {
        // console.log("六个统计------》》》", res);
        //this.tableData = res.data;
        this.listObj.forEach((item, i) => {
          if (res.data[i].name == item.name) {
            item.value = res.data[i].amount || res.data[i].num;
          }
        });

      });
    },
    cityList(id,node,successFn) {
      cityList({
        regionId: id,
      }).then((res) => {
        let zht = [];
        let street = [];
        let sq = [];
        let index = 0;
        res.data.map((val,ind) => {
          val.leaf = false;
          val.children = [];
          val.value = val.regionId;
          val.label = val.regionName;
          val.flag = 1;
          val.isDisabled = this.initCitys.provinceId && (val.regionType === 0 || val.regionType === 1) ?
              this.disabled : this.initCitys.cityId && val.regionType === 2 ? this.disabled :
                  this.initCitys.areaId && val.regionType === 3 ? this.disabled : false;

          //如果带默认参数，参数的同级级设为叶子节点，下级不是叶子节点，参数对应节点不是叶子节点且最后一级不禁用。
          if(this.$route.query && this.initCitys.provinceId){
            val.leaf = true;
            if(this.initCitys.provinceId && this.initCitys.cityId && this.initCitys.areaId){
              if(val.regionType > 3){
                val.leaf = false;
              }
              if(this.initCitys.areaId == val.regionId){
                val.isDisabled = false
              }
            }else if (this.initCitys.provinceId && this.initCitys.cityId){
              if(val.regionType > 2){
                val.leaf = false;
              }
              if(this.initCitys.cityId == val.regionId){
                val.isDisabled = false
              }
            }else if (this.initCitys.provinceId){
              if(val.regionType > 1){
                val.leaf = false;
              }
              if(this.initCitys.areaId == val.regionId){
                val.isDisabled = false
              }
            }
          }
          if(val.regionId == this.initCitys.provinceId || val.regionId == this.initCitys.cityId || val.regionId == this.initCitys.areaId){
            index = ind
            val.leaf = false;
          }
          if (val.regionType === 4) {
            val.leaf = false;
            street.push(val)
          } else if (val.regionType === 6) {
            val.leaf = true;
            zht.push(val)
          } else if (val.regionType === 8) {
            val.leaf = true;
            sq.push(val)
          } else if (val.regionType === 7) {
            val.leaf = true;
          }
          return val
        })
        let arrs = [];
        if (id === 1) {
          let option = [];
          option.push({
            regionType: '0',
            flag: 1,
            regionId: 1,
            value: 1,
            label:"全国",
            regionName: "全国",
            leaf: true,
            isDisabled: this.disabled
          })
          arrs = option.concat(res.data)
        } else if (id !== 1 && node) {
          if (res.data.length) {
            if (street.length || zht.length || sq.length) {
              arrs = [{
                regionId: id + '-4',
                value:id + '-4',
                label:"乡镇街道",
                regionName: "乡镇街道",
                regionType: 4,
                flag: 4,
                leaf: street.length ? false : true,
                children: street,
              }, {
                regionId: id + '-8',
                regionName: "商圈",
                value:id + '-8',
                label:"商圈",
                regionType: 8,
                flag: 8,
                leaf: sq.length ? false : true,
                children: sq,
              }, {
                regionId: id + '-6',
                regionName: "综合体",
                value:id + '-6',
                label:"综合体",
                regionType: 6,
                flag: 6,
                leaf: zht.length ? false : true,
                children: zht,
              } ]
            } else {
              arrs = res.data;
            }
          } else {
            node.leaf = true;
          }
        }

        if (successFn) {
          successFn(arrs,index)
        }
      })

    },
    lazyLoad (node, resolve) {
      // if(node.isDisabled) return false;
      this.noData = false;
      this.activeInd++;
      this.paramLen++;

      this.nodeList.splice(this.activeInd,this.nodeList.length - this.activeInd + 1, node)
      this.cascaders.splice(this.activeInd,this.cascaders.length - this.activeInd + 1, node.regionId)
      if(node.leaf) {
        this.activeInd--;
        if(this.activeInd < 0){
          console.log("-1--------")
          this.activeInd = 0;
        }
      }
      console.log("node",node)

      this.setMove(this.activeInd)
      let id = 1;
      if(node && node.regionId){
        id = node.regionId
      }
      let query = this.$route.query;
      if(query.cityId && query.cityId == id){
        this.cityName = node.regionName
      }
      if(query.areaId && query.areaId == id){
        this.areaName = node.regionName
      }

       if(node.label !== '乡镇街道' && node.label !== '商圈' && node.label !== '综合体'){
        this.cityList(id,node,(list)=>{

          // resolve(list)
          if(!node.leaf){
            node.children = list;
          }
          if(!list.length){
            this.noData = true
          }
        });
      }
      this.areaChange(node)
    },
    areaChange(node,emit){
      let regionId = node.regionId;
      console.log("areaChange",node)
      if(node.flag == 4 || node.flag == 6 || node.flag == 8 ){
        regionId = node.value.split("-")[0] - 0;
      }

      let regionPrevData = clone(this.regionData)
      this.regionData = {
        regionType: node.regionType,
        regionName: node.regionName,
        regionId: regionId,
        flag: node.flag,
        cityName: this.nodeList[2] ? this.nodeList[2].regionName : '',
        areaName: this.nodeList[3] ? this.nodeList[3].regionName : ''
      }

      console.log("regionPrevData",regionPrevData)
      console.log("regionData",this.regionData)
      this.isChange = false;
      //组件的值变化后关闭下拉框，并且把值传递出去。
      if(this.initCitys.provinceId == 1 || (regionPrevData.regionId !== this.regionData.regionId) || (regionPrevData.regionName !== this.regionData.regionName)){
        this.isChange = true;
      }
      if(emit){
        this.siteChange()
      }
    },
    siteChange(){
      console.log("isChange",this.isChange)
      if(this.isChange){
        this.siteName = this.regionData.regionName;
        this.sixStatistics()
        this.$emit("regionData",this.regionData)
      }
      this.popupVisible = false;
    },
    siteCancle(){
      console.log(this.initList)

      // this.nodeList = [this.initList[0]];
      // this.activeInd = 0;
      // this.cascaders = [1];
      // this.setMove(this.activeInd)
      this.popupVisible = false;
    },
    getTime() {
      getTime().then((res) => {
        this.time = res.data;
      });
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.headertoptitle {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  font-size: 34px;
  color: #303030;
  background: white;
  justify-content: center;
  position: relative;
  .headertoptitleback{
    position: absolute;
    width: 17px;
    height: 30px;
    background: url(../assets/imgs/back.png) no-repeat;
    background-size: 100% 100%;
    top: 50%;
    margin-top: -15px;
    left: 32px;
  }
}
.header {
  padding: 0px 30px;
  background-color: rgb(243, 244, 245);

  .header-top {
    height: 124px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #232a24;
    font-size: 24px;
    .header-left {
      line-height: 1;
      & > p {
        color: #6c6c6c;
        font-size: 20px;
        margin-bottom: 24px;
      }
    }
    .header-right {
      line-height: 1;
      display: flex;
      align-items: center;
      & > p {
        margin-right: 10px;
        img {
          width: 20px;
        }
      }
    }
  }
  .header-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #626262;
    padding-bottom: 12px;
    li {
      width: 192px;
      height: 110px;
      padding: 0 10px 0 18px;
      margin-bottom: 20px;
      border-radius: 10px;
      background-color: rgb(255, 255, 255);
      box-shadow: -0.279px 3.99px 30px 0px rgba(165, 189, 251, 0.4);
      line-height: 1;
      & > p {
        font-size: 24px;
        padding: 20px 0 24px;
        color: #626262;
      }
      & > div {
        font-size: 24px;
        color: #232a24;
      }
      &.active {
        background-color: rgb(77, 118, 224);
        box-shadow: -0.279px 3.99px 30px 0px rgba(165, 189, 251, 0.4);
        &>p,&>div{
          color: #fff;
        }
      }
    }
  }

  .pickers {
    width: 100vw;
    height: calc(55vh - 100px);
    &::v-deep .mint-indexlist-content {
      height: 100% !important;
    }
  }
  .picker-toolbar-title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    line-height: 100px;
    font-size: 29px;
    border-bottom: 1px solid #eee;
    .usi-btn-name{
      font-size: 32px;
    }
    .usi-btn-cancel {
      color: #232a24;
    }
    .usi-btn-sure {
      color: #1d67ff;
    }
  }

  .tab-top {
    margin: 0px 40px ;
    height: 80px;
    line-height: 80px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 1px solid #eee;
    padding: 0 25px;
    &>img{
      width: 22px;
      vertical-align: text-bottom;
      margin-right: 15px;
    }
    li {
      font-size: 29px;
      text-align: center;
      display: inline-block;
      &.active{
        color: #4d76e0;
      }
      span{
        padding: 0 10px;
      }
    }
  }
  .tab-bottom {
    width: 100%;
    height: calc(55vh - 180px);
    position: relative;

    .tab-container{
      transition: left 0.4s;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      &>li {
        width: 100vw;
        height: 100%;
      }
    }

  }
  .tab-list {
    height: 100%;
    overflow: auto;
    padding: 0 40px;
    li {
      height: 80px;
      line-height: 80px;
      font-size: 32px;
      padding-left: 20px;
      color: #312d37;
      border-bottom: 1px solid #eee;
      .el-icon-check{
        color: transparent;
      }
      &.active {
        color: #4d76e0;
        .el-icon-check{
          color: #4d76e0;
        }
      }
    }
  }
  .nodata{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }
  .item {
    display: inline-block;
  }

  .nav {
    padding: 10px;
  }

  .link {
    color: inherit;
    padding: 20px;
    display: block;
  }
}
</style>
