import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/HomePage",
    component: Home,
    children: [
      {
        path: "/HomePage",
        name: "HomePage",
        component: () => import( "@/views/HomePage.vue")
      },
      {
        path: "/",
        name: "HomePage",
        component: () => import( "@/views/HomePage.vue")
      },
      {
        path: "/HomeTrade",
        name: "HomeTrade",
        component: () => import( "@/views/HomeTrade.vue")
      },
      {
        path: "/HomeSupervise", //资金监管
        name: "HomeSupervise",
        component: () => import( "@/views/HomeSupervise.vue")
      },
      {
        path: "/HomeAcceptance",
        name: "HomeAcceptance",
        component: () => import( "@/views/HomeAcceptance.vue")
      },
      {
        path: "/HomePlace",
        name: "HomePlace",
        component: () => import( "@/views/HomePlace.vue")
      },
      {
        path: "/HomePolice",
        name: "HomePolice",
        component: () => import( "@/views/HomePolice.vue")
      },
      {
        path: "/HomePrepaidcontract",
        name: "HomePrepaidcontract",
        component: () => import( "@/views/HomePrepaidcontract.vue")
      },
      {
        path: "/HomeConsumptionstructure",
        name: "HomeConsumptionstructure",
        component: () => import( "@/views/HomeConsumptionstructure.vue")
      },
      {
        path: "/HomeEpidemicpreventioncontrol",
        name: "HomeEpidemicpreventioncontrol",
        component: () => import( "@/views/HomeEpidemicpreventioncontrol.vue")
      },
      {
        path: "/HomeSynthesizeSupervise",
        name: "HomeSynthesizeSupervise",
        component: () => import( "@/views/HomeSynthesizeSupervise.vue")
      },
      {
        path: "/HomeTaskCoordination",
        name: "HomeTaskCoordination",
        component: () => import( "@/views/HomeTaskCoordination.vue")
      },
      {
        path: "/HomeOther",
        name: "HomeOther",
        component: () => import( "@/views/HomeOther.vue")
      }

    ],
  },
  {
    path: "/Intelligent",
    name: "Intelligent",
    component: () => import( "@/views/Intelligent.vue")
  },
  {
    path: "/IntelligentAnalysis",
    name: "IntelligentAnalysis",
    component: () => import( "@/views/IntelligentAnalysis.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    hidden:true
  },
  {
    path: "/testPage",
    name: "testPage",
    component: () => import("@/views/testPage"),
    hidden:true
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  // mode: 'hash',
});

function parseSearchArgs() {
  var url = window.location.search; //获取url中"?"符后的字串
  var rst = {};
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var parts = str.split("&");
    for(var i = 0; i < parts.length; i++) {
      rst[parts[i].split("=")[0]]=decodeURI(parts[i].split("=")[1]);
    }
  }
  return rst;
}



router.beforeEach((to, from, next)=>{

  if(to.path == "/IntelligentAnalysis"){
    return next()
  }
  let params = parseSearchArgs();
  let initUrl = {};
  // console.log(params,'params====')
  if(params.provinceId){
    // console.log(10)
    window.sessionStorage.setItem('initUrl',JSON.stringify(params))
    initUrl = JSON.parse(window.sessionStorage.getItem('initUrl')) || false;
    if(to.query.provinceId === initUrl.provinceId){
      // console.log(11)
      next()
    }else{
      // console.log(22)
      next({
        path: to.path,
        query: initUrl
      })
    }
  }else{

    if(initUrl && initUrl.provinceId){
      if(to.query.provinceId === initUrl.provinceId){
        next()
      }else{
        next({
          path: to.path,
          query: initUrl
        })
      }
    }else{
      next()
    }

  }

})

export default router;
