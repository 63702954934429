<template>
  <div class="home">
    <Header  @regionData="regionData" v-if="$route.name !== 'PdfDownload'"></Header>
    <router-view  :regionName="regionName" :cityName="cityName" :areaName="areaName" :flag="flag" :regionId="regionId" :regionType="regionType"></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header"
export default {
  name: 'Home',
  data(){
    return {
      regionName:'全国',
      cityName:'',
      areaName:'',
      flag: 1,
      regionId:'1',
      regionType:'0',
    }
  },
  components: {
    Header,
  },
  created(){
  },
  mounted() {

  },
  methods:{
    regionData(data){
      console.log(data)
      if(data.regionId){
        this.cityName= data.cityName;
        this.areaName= data.areaName;
        this.regionName = data.regionName;
        this.flag = data.flag;
        this.regionId = data.regionId;
        this.regionType = data.regionType;
      }else{
        this.regionId = 1;
        this.regionType = "0";
        this.regionName = "全国";
        this.cityName= "";
        this.areaName= "";
        this.flag = 1;
      }

    }
  }
}
</script>
