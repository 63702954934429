
export const ERR_OK = 200

export const TIMEOUT = 500000

export const STATUS = 'code' // 后台返回的状态码，如 code status 这些

export const baseURL = window.BASEURL

window.jsonBaseUrl =
  process.env.NODE_ENV === "production" ?'' :'';