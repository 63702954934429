import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'
  
/**
 * 行政区划查询
 */
export const cityList = (params) => {
    return $api.get({
        url: baseURL + '/api/common/cityList',
        params
    })
}  
/**
 * 六个统计
 */
export const sixStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/sixStatistics',
        params
    })
}
/**
 * 企业/商户发卡类型下拉框
 */
export const cardType = (params) => {
    return $api.get({
        url: baseURL + '/api/common/cardType',
        params
    })
}

/**
 * 获取服务器时间
 */
export const getTime = (params) => {
    return $api.get({
        url: baseURL + '/api/common/getTime',
        params
    })
}
