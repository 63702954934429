<template>
  <div class="kcSelect">
    <!--    <button @click="popupVisible = true">打开</button>-->
    <div
      class="diy-button"
      :class="{ active: popupVisible, isBlue: isBlue }"
      @click="popupVisible = true"
    >
     <!-- :style="{ left: left + 'px', top: top + 'vw', right: right + 'px' }" -->
      <p>{{ popupName }}</p>
      <i class="el-icon-arrow-down"></i>
    </div>
    <mt-popup v-model="popupVisible" position="bottom">
      <mt-picker
        :slots="popupSlots"
        @change="onValuesChange"
        valueKey="name"
        showToolbar
        class="picker"
      >
        <div class="picker-toolbar-title">
          <div class="usi-btn-cancel" @click="popupVisible = !popupVisible">
            取消
          </div>
          <div class="usi-btn-name">请选择</div>
          <div class="usi-btn-sure" @click="selectChange">确定</div>
        </div>
      </mt-picker>
    </mt-popup>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "Title",
  props: {
    isBlue: [Boolean],
    left: [Number, String],
    right: [Number, String],
    top: [Number, String],
    isAll: [Boolean],
    //列表数据
    optionData: [Array],
    //自定义key值
    keyLabel: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      popupVisible: false,
      popupSlots: [
        //问题类型弹框数据
        {
          values: [
            { name: "近一周", id: 1 },
            { name: "近一月", id: 2 },
            { name: "近半年", id: 3 },
            { name: "全部", id: 4 },
          ],
        },
      ],
      popupVal: {},
      popupName: "请选择",
    };
  },
  watch: {
    optionData: function (val) {
      this.initData();
    },
  },
  created() {
    console.log(this.optionData);
    this.initData();
  },
  methods: {
    initData() {
      if (this.optionData && this.optionData.length) {
        this.popupSlots[0].values = this.optionData;
      }
      if (this.popupSlots[0].values.length) {
        this.popupVal = this.popupSlots[0].values[0];
        this.selectChange(true);  //初始只显示当前选中label
      }
    },
    //问题类型的弹框picker值发生改变
    onValuesChange(picker, values) {
      this.popupVal = values[0];
    },
    //弹框点击确认
    selectChange(showLabel) {
      // console.log(this.popupVal)
      this.popupVisible = false;
      this.popupName = this.popupVal.name;
      if(showLabel !== true){
        this.$emit("popupVal", this.popupVal[this.keyLabel], this.popupVal);
      }

    },
  },
  components: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.kcSelect {
  position: relative;
  // left: 0;

  .diy-button {
    z-index: 9;
    position: absolute;
    left: initial;
    // top: initial;
    top: -75px;
    right: 0;
    border: 2px solid #6c6c6c;
    min-width: 102px;
    max-width: 210px;
    padding: 0 17px;
    height: 52px;
    border-radius: 8px;
    font-size: 20px;
    color: #232a24;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-icon-arrow-down {
      margin-left: 17px;
      transition: all 0.3s;
    }
    &.active {
      color: #1d67ff;
      border: 2px solid #1d67ff;
      .el-icon-arrow-down {
        transition: all 0.3s;
        transform: rotate(180deg);
      }
    }
    &.isBlue {
      color: #1d67ff;
      border: 2px solid #1d67ff;
    }
  }
  .picker {
    width: 100vw;
    height: 40vh;
    &::v-deep .picker-toolbar {
      height: 80px;
      line-height: 1;
      padding-top: 30px;
      padding-bottom: 10px;
      .picker-toolbar-title {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 29px;
        .usi-btn-name {
          font-size: 32px;
        }
        .usi-btn-cancel {
          color: #232a24;
        }
        .usi-btn-sure {
          color: #1d67ff;
        }
      }
    }
    //&::v-deep .picker-slot-center{
    //  width: 100%;
    //}
  }

}
</style>
